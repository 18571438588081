import { Storage, StorageKey, StorageOptions } from '../Types'

/**
 * Attention: Don't use this class in production!
 */
class DummyStorage implements Storage<StorageOptions> {
  store: { [key: string]: string | undefined }
  options: StorageOptions = {}

  constructor(options: StorageOptions = {}) {
    this.options = options
    this.store = {}
  }

  async get(key: StorageKey): Promise<string | undefined> {
    if (!key) return undefined
    return this.store[key]
  }

  async set(key: StorageKey, value: string | undefined): Promise<boolean> {
    if (!key) return false
    this.store[key] = value
    return true
  }
}

export default DummyStorage
