import AES from 'crypto-js/aes'
import encUTF8 from 'crypto-js/enc-utf8'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

import Config from './Config'
import { isNumGt0, isPresent } from './utils'

import type {
  OAuthTokenData, OAuthTokenInfoData, OAuthUserData, Storage,
  StorageAuthData,
  StorageOptions,
  StoragePkceData,
  StorageUserData
} from './Types'

// ------------------------------------------------------
// Storage
// ------------------------------------------------------

export function getAuthStorage(): Storage<StorageOptions> {
  return Config.get().authStorage
}

export function getUserInfoStorage(): Storage<StorageOptions> {
  return Config.get().userInfoStorage
}

// ------------------------------------------------------
// Simple Getter / Setter
// ------------------------------------------------------

export async function getToken(): Promise<string | undefined> {
  return decryptString(await getAuthStorage().get('token'))
}

export async function setToken(value?: string): Promise<void> {
  await getAuthStorage().set('token', encryptionString(value))
}

export async function getRefreshToken(): Promise<string | undefined> {
  return decryptString(await getAuthStorage().get('refreshToken'))
}

export async function setRefreshToken(value?: string): Promise<void> {
  await getAuthStorage().set('refreshToken', encryptionString(value))
}

export async function getAuthInfo(): Promise<StorageAuthData> {
  const jsonData = await getAuthStorage().get('authData')
  return parseJsonData<StorageAuthData>(jsonData)
}

export async function setAuthInfo(value?: StorageAuthData): Promise<void> {
  await getAuthStorage().set('authData', encryptionString(
    JSON.stringify(value)
  ))
}

export async function getReturnData<T>(): Promise<T> {
  const jsonData = await getAuthStorage().get('authReturnData')
  return parseJsonData<T>(jsonData)
}

export async function setReturnData<T>(value?: T): Promise<void> {
  await getAuthStorage().set('authReturnData', encryptionString(
    JSON.stringify(value)
  ))
}

export async function getPkceData(): Promise<StoragePkceData> {
  const jsonData = await getAuthStorage().get('pkceData')
  return parseJsonData<StoragePkceData>(jsonData)
}

export async function setPkceData(value?: StoragePkceData): Promise<void> {
  await getAuthStorage().set('pkceData', encryptionString(
    JSON.stringify(value)
  ))
}

export async function getUserData(): Promise<StorageUserData> {
  const jsonData = await getUserInfoStorage().get('userData')
  return parseJsonData<StorageUserData>(jsonData)
}

export async function setUserData(value?: StorageUserData): Promise<void> {
  await getUserInfoStorage().set('userData', encryptionString(
    JSON.stringify(value)
  ))
}

export async function getLastOAuthAuthorizationCodeFlowAt(): Promise<Date | undefined> {
  const value = Number(decryptString(
    await getAuthStorage().get('authLastOAuthAuthorizationCodeFlow')
  ))

  return isNumGt0(value) ? new Date(value * 1000) : undefined
}

export async function setLastOAuthAuthorizationCodeFlowAt(
  _date = new Date()
): Promise<Date | undefined> {
  const nowSecondsStr = `${_date.getTime() / 1000}`

  await getAuthStorage().set('authLastOAuthAuthorizationCodeFlow', encryptionString(
    `${parseInt(nowSecondsStr, 10)}`
  ))

  return _date
}

// ------------------------------------------------------
// Additional Getter / Setter
// ------------------------------------------------------

/**
 * Returns the token including it's type
 */
export async function getTokenWithType(): Promise<string | undefined> {
  const token = await getToken()
  const { tokenType } = await getAuthInfo()
  return `${tokenType} ${token}`
}

/**
 * Sets the current date time as last activity to storage
 */
export async function setLastActivity(_date = new Date()): Promise<Date | undefined> {
  const data = await getAuthInfo()
  data.lastActivity = parseInt(`${_date.getTime() / 1000}`, 10)
  await setAuthInfo(data)
  return _date
}

/**
 * Returns the parsed last activity date
 */
export async function getLastActivity(): Promise<Date | undefined> {
  const { lastActivity } = await getAuthInfo()
  return isNumGt0(lastActivity) ? new Date(lastActivity * 1000) : undefined
}

/**
 * Return the stored PKCE verifier
 */
export async function getPkceVerifier(): Promise<string | undefined> {
  const { verifier } = await getPkceData()
  return verifier
}

/**
 * Stores data from the token call
 */
export async function setAuthInfoByOAuthTokenData(
  data: OAuthTokenData,
): Promise<void> {
  const fetchedAt = parseInt(`${new Date().getTime() / 1000}`, 10)
  const scope = isArray(data.scope) ? data.scope.join(',') : data.scope
  const storageData = {
    expiresIn: data.expires_in,
    createdAt: data.created_at,
    expiresAt: fetchedAt + data.expires_in,
    tokenType: data.token_type,
    lastActivity: fetchedAt,
    scope,
    fetchedAt,
  }
  await setAuthInfo(storageData)
  await setToken(data.access_token)
  await setRefreshToken(data.refresh_token)
}

/**
 * Stores data from the token info call
 */
export async function setAuthInfoByOAuthTokenInfoData(
  data: OAuthTokenInfoData,
): Promise<void> {
  const fetchedAt = parseInt(`${new Date().getTime() / 1000}`, 10)
  const scope = isArray(data.scope) ? data.scope.join(',') : data.scope
  const storageData = await getAuthInfo()

  storageData.expiresIn = data.expires_in
  storageData.createdAt = data.created_at
  storageData.expiresAt = fetchedAt + data.expires_in
  storageData.lastActivity = fetchedAt
  storageData.fetchedAt = fetchedAt
  storageData.scope = scope

  await setAuthInfo(storageData)
}

export async function setUserDataByOAuthUserData(
  data: OAuthUserData,
): Promise<void> {
  const fetchedAt = parseInt(`${new Date().getTime() / 1000}`, 10)
  const storageData = {
    ...data,
    fetchedAt,
  }
  await setUserData(storageData)
}

export async function resetToken(): Promise<void> {
  setToken()
}

export async function resetRefreshToken(): Promise<void> {
  setRefreshToken()
}

export async function resetAuthInfo(): Promise<void> {
  setAuthInfo()
}

export async function resetUserInfo(): Promise<void> {
  setUserData()
}

// ------------------------------------------------------
// Helper
// ------------------------------------------------------

/**
 * Parse JSON string into passed format.
 */
export function parseJsonData<T>(jsonData?: string): T {
  if (!isPresent(jsonData)) return {} as T

  jsonData = decryptString(jsonData)

  try {
    const data: T = JSON.parse(jsonData)
    return isObject(data) ? data : {} as T
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.warn(e)
      return {} as T
    }
    throw e
  }
}

/**
 * Decryptes the passed value. If Config value `encryptionSecret`
 * is not set, the original value will be returned.
 */
export function decryptString(value?: string): string {
  if (!isPresent(value)) return ''
  if (!isPresent(Config.get().encryptionSecret)) return value

  return AES.decrypt(value, Config.get().encryptionSecret ?? '').toString(encUTF8)
}

/**
 * Encryptes the passed value. If Config value `encryptionSecret`
 * is not set, the original value will be returned.
 */
export function encryptionString(value?: string): string {
  if (!isPresent(value)) return ''
  if (!isPresent(Config.get().encryptionSecret)) return value

  return AES.encrypt(value, Config.get().encryptionSecret ?? '').toString()
}

/**
 * StorageManager
 */
const StorageManager = {
  getAuthStorage,
  getUserInfoStorage,
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  getAuthInfo,
  setAuthInfo,
  getReturnData,
  setReturnData,
  getPkceData,
  setPkceData,
  getUserData,
  setUserData,
  getLastOAuthAuthorizationCodeFlowAt,
  setLastOAuthAuthorizationCodeFlowAt,
  getTokenWithType,
  setLastActivity,
  getLastActivity,
  getPkceVerifier,
  setAuthInfoByOAuthTokenData,
  setAuthInfoByOAuthTokenInfoData,
  setUserDataByOAuthUserData,
  resetToken,
  resetRefreshToken,
  resetAuthInfo,
  resetUserInfo,
  parseJsonData,
  decryptString,
  encryptionString,
}
export default StorageManager
