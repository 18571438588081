import type { OAuthErrorReason } from '../Types'

/**
 * OAuthError which contains dedicated error codes
 * as "reason" attribute.
 */
class OAuthError extends Error {
  /**
   * Error reason code as string
   */
  reason: OAuthErrorReason | undefined = undefined

  constructor(
    message: string,
    reason?: OAuthErrorReason
  ) {
    super(message)

    // necessary to assure "instanceof" checks
    Object.setPrototypeOf(this, OAuthError.prototype)

    this.reason = reason

    if (Error.captureStackTrace) Error.captureStackTrace(this, OAuthError)

    return this
  }
}

export default OAuthError
